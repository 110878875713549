import { getSignedInUser } from "../../../Auth/services/user";
import moment from "moment/moment";

export const generateConsultFirebaseDocument = ({ requestData, zendeskId }) => {
  let { user, consultationState } = requestData;

  let {
    hasConsultedWithPsychOutsideProgram,
    experienceWithPsychOutsideProgram,
    hasConsultedWithPsychInProgram,
    previousPsychName,
    hasBeenDiagnosed,
    mentalIllnessDiagnosis,
    hasFamilyHistory,
    specificFamilyHistory,
    hasBeenHospitalized,
    specificHospitalizationDetails,
    isTakingPsychMeds,
    psychMedsDetails,
    isTakingNonPsychMeds,
    nonPsychMedsDetails,
    hasDrugAllergies,
    drugAllergiesDetails,
    causeOfDistress,
    specificCauseOfDistress,
    durationCurrentCauseOfDistress,
    currentMentalHealthCondition,

    kesslerNervous,
    kesslerHopeless,
    kesslerRestless,
    kesslerDepressed,
    kesslerEffort,
    kesslerWorthless,

    hasWishedToBeDead,
    hadSuicidalThoughts,
    hasThoughtSuicidalMethod,
    hadSuicidalIntent,
    hadSuicidalIntentWithPlan,
    hadActedOnSuicidalThoughts,
    suicideAttemptRecency,
    nonSuicidalSelfInjury,
    hasThoughtsOfHarmingOthers,

    firstChoiceSchedule,
    secondChoiceSchedule,
    thirdChoiceSchedule,
    ifScheduleUnavailable,
    specificIfScheduleUnavailable,
    therapistPreference,
    otherNotes,
  } = consultationState;

  const preferredSchedule = {
    firstChoiceSchedule,
    secondChoiceSchedule,
    thirdChoiceSchedule,
  };
  return {
    created: { at: new Date(), authUid: user.authUid },
    preferredSchedule,

    hasConsultedWithPsychOutsideProgram,
    experienceWithPsychOutsideProgram,
    hasConsultedWithPsychInProgram,
    previousPsychName,
    hasBeenDiagnosed,
    mentalIllnessDiagnosis,
    hasFamilyHistory,
    specificFamilyHistory,
    hasBeenHospitalized,
    specificHospitalizationDetails,
    isTakingPsychMeds,
    psychMedsDetails,
    isTakingNonPsychMeds,
    nonPsychMedsDetails,
    hasDrugAllergies,
    drugAllergiesDetails,
    causeOfDistress,
    specificCauseOfDistress,
    durationCurrentCauseOfDistress,
    currentMentalHealthCondition,

    kesslerNervous,
    kesslerHopeless,
    kesslerRestless,
    kesslerDepressed,
    kesslerEffort,
    kesslerWorthless,

    hasWishedToBeDead,
    hadSuicidalThoughts,
    hasThoughtSuicidalMethod,
    hadSuicidalIntent,
    hadSuicidalIntentWithPlan,
    hadActedOnSuicidalThoughts,
    suicideAttemptRecency,
    nonSuicidalSelfInjury,
    hasThoughtsOfHarmingOthers,

    ifScheduleUnavailable,
    specificIfScheduleUnavailable,
    therapistPreference,
    otherNotes,

    userUid: user.id,
    authUid: user.authUid,
    type: "teleconsult",
    zendeskId: zendeskId,
  };
};

export const appointmentZendeskTemplate = (data) => {
  let { user, consultationState, programCodes } = data;
  let {
    streetAddress,
    barangay,
    city,
    province,
  } = getSignedInUser().addresses.addresses[0];

  let {
    firstName,
    lastName,
    email,
    personalEmail,
    employee,
    emergencyContact,
    sex,
    birthday,
    mobileNumber,
    maxicareCardNumber,
  } = user;

  let {
    hasConsultedWithPsychOutsideProgram,
    experienceWithPsychOutsideProgram,
    hasConsultedWithPsychInProgram,
    previousPsychName,
    hasBeenDiagnosed,
    mentalIllnessDiagnosis,
    hasFamilyHistory,
    specificFamilyHistory,
    hasBeenHospitalized,
    specificHospitalizationDetails,
    isTakingPsychMeds,
    psychMedsDetails,
    isTakingNonPsychMeds,
    nonPsychMedsDetails,
    hasDrugAllergies,
    drugAllergiesDetails,
    causeOfDistress,
    specificCauseOfDistress,
    durationCurrentCauseOfDistress,
    currentMentalHealthCondition,

    kesslerNervous,
    kesslerHopeless,
    kesslerRestless,
    kesslerDepressed,
    kesslerEffort,
    kesslerWorthless,

    hasWishedToBeDead,
    hadSuicidalThoughts,
    hasThoughtSuicidalMethod,
    hadSuicidalIntent,
    hadSuicidalIntentWithPlan,
    hadActedOnSuicidalThoughts,
    suicideAttemptRecency,
    nonSuicidalSelfInjury,
    hasThoughtsOfHarmingOthers,

    firstChoiceSchedule,
    secondChoiceSchedule,
    thirdChoiceSchedule,
    ifScheduleUnavailable,
    specificIfScheduleUnavailable,
    therapistPreference,
    otherNotes,
  } = consultationState;

  const dateString = `${birthday.month} ${birthday.date}, ${birthday.year}`;
  let airtableFormattedBirthdate = moment(dateString);
  airtableFormattedBirthdate = airtableFormattedBirthdate.format("YYYY-MM-DD");

  // dynamically creates Zendesk templates based on availability of information
  // checks if the values is truthy -> meaning the value is not undefined, null, 0, "", NaN, and false
  // Personal Information
  const bodyFirstName = !!firstName ? `First Name: ${firstName}\n` : "";
  const bodyLastName = !!lastName ? `Last Name: ${lastName}\n` : "";
  const bodyBirthday = !!birthday
    ? `Birthday: ${airtableFormattedBirthdate}\n`
    : "";
  const bodyGender = !!sex ? `Gender: ${sex}\n` : "";
  const bodyMaxicareCardNumber = !!maxicareCardNumber
    ? `Maxicare Card Number: ${maxicareCardNumber}\n`
    : "";
  const bodyEmail = !!email ? `Email: ${email}\n` : "";
  const bodyMobileNumber = !!mobileNumber
    ? `Mobile Number: ${mobileNumber}\n`
    : "";
  const bodyAddress = !!streetAddress
    ? `Address: ${streetAddress}, ${
        barangay ? barangay + `,` : ""
      } ${city}, ${province}\n`
    : "";
  const personalInformationBody =
    `- Personal Information -\n` +
    bodyFirstName +
    bodyLastName +
    bodyBirthday +
    bodyGender +
    bodyMaxicareCardNumber +
    bodyEmail +
    bodyMobileNumber +
    bodyAddress;

  // Mental Health Information
  const causeOfDistressBody = !!causeOfDistress
    ? `What made you seek mental health support? ${
        causeOfDistress === "Others" ? specificCauseOfDistress : causeOfDistress
      }\n`
    : "";
  const durationCurrentCauseOfDistressBody = !!durationCurrentCauseOfDistress.value
    ? `How long have you been experiencing this? ${durationCurrentCauseOfDistress.value}\n`
    : "";
  const currentMentalHealthConditionBody = !!currentMentalHealthCondition
    ? `What are you currently experiencing? ${currentMentalHealthCondition}\n`
    : "";
  const mentalHealthInformationBody =
    `\n- Mental Health Information -\n` +
    causeOfDistressBody +
    durationCurrentCauseOfDistressBody +
    currentMentalHealthConditionBody;

  // Medical History
  const bodyHasConsultedWithPsychOutsideProgram = !!hasConsultedWithPsychOutsideProgram
    ? `Have you consulted a psychologist/psychiatrist in any other setting before? ${hasConsultedWithPsychOutsideProgram}\n`
    : "";
  const bodyExperienceWithPsychOutsideProgram =
    hasConsultedWithPsychOutsideProgram === "Yes"
      ? !!experienceWithPsychOutsideProgram.value
        ? `How was your experience? ${experienceWithPsychOutsideProgram.value}\n`
        : ""
      : "";
  const bodyHasConsultedWithPsychInProgram = !!hasConsultedWithPsychInProgram
    ? `Have you consulted a psychologist/psychiatrist under MedGrocer before? ${hasConsultedWithPsychInProgram}\n`
    : "";
  const bodyPreviousPsychName =
    hasConsultedWithPsychInProgram === "Yes"
      ? !!previousPsychName
        ? `Who among our mental health professionals have you consulted with before? ${previousPsychName}\n`
        : ""
      : "";
  const bodyHasBeenDiagnosed = !!hasBeenDiagnosed
    ? `Have you ever been diagnosed with a mental condition? ${hasBeenDiagnosed}\n`
    : "";
  const bodyMentalIllnessDiagnosis =
    hasBeenDiagnosed === "Yes"
      ? !!mentalIllnessDiagnosis
        ? `When were you diagnosed? What were you diagnosed with? ${mentalIllnessDiagnosis}\n`
        : ""
      : "";
  const bodyHasFamilyHistory = !!hasFamilyHistory
    ? `Is there a history of mental condition in your family, including relatives? ${hasFamilyHistory}\n`
    : "";
  const bodySpecificFamilyHistory =
    hasFamilyHistory === "Yes"
      ? !!specificFamilyHistory
        ? `Who in your family was diagnosed? What were they diagnosed with? ${specificFamilyHistory}\n`
        : ""
      : "";
  const bodyHasBeenHospitalized = !!hasBeenHospitalized
    ? `Have you been hospitalized for mental health reasons? ${hasBeenHospitalized}\n`
    : "";
  const bodySpecificHospitalizationDetails =
    hasBeenHospitalized === "Yes"
      ? !!specificHospitalizationDetails
        ? `When were you hospitalized? What was the cause?  ${specificHospitalizationDetails}\n`
        : ""
      : "";
  const bodyIsTakingPsychMeds = !!isTakingPsychMeds
    ? `Have you taken or are currently taking psychiatric medicines? ${isTakingPsychMeds}\n`
    : "";
  const bodyPsychMedDetails =
    isTakingPsychMeds === "Yes"
      ? !!psychMedsDetails
        ? `Which meds are these? What dose? ${psychMedsDetails}\n`
        : ""
      : "";
  const bodyIsTakingNonPsychMeds = !!isTakingNonPsychMeds
    ? `Are you currently taking non-psychiatric medicines? ${isTakingNonPsychMeds}\n`
    : "";
  const bodyNonPsychMedDetails =
    isTakingNonPsychMeds === "Yes"
      ? !!nonPsychMedsDetails
        ? `Which meds are these? What dose? ${nonPsychMedsDetails}\n`
        : ""
      : "";
  const bodyHasDrugAllergies = !!hasDrugAllergies
    ? `Do you have drug allergies? ${hasDrugAllergies}\n`
    : "";
  const bodyDrugAllergiesDetails =
    hasDrugAllergies === "Yes"
      ? !!drugAllergiesDetails
        ? `Which drugs are these? ${drugAllergiesDetails}\n`
        : ""
      : "";
  const medicalHistoryBody =
    `\n- Medical History -\n` +
    bodyHasConsultedWithPsychOutsideProgram +
    bodyExperienceWithPsychOutsideProgram +
    bodyHasConsultedWithPsychInProgram +
    bodyPreviousPsychName +
    bodyHasBeenDiagnosed +
    bodyMentalIllnessDiagnosis +
    bodyHasFamilyHistory +
    bodySpecificFamilyHistory +
    bodyHasBeenHospitalized +
    bodySpecificHospitalizationDetails +
    bodyIsTakingPsychMeds +
    bodyPsychMedDetails +
    bodyIsTakingNonPsychMeds +
    bodyNonPsychMedDetails +
    bodyHasDrugAllergies +
    bodyDrugAllergiesDetails;

  // Screening
  const kesslerNervousBody = !!kesslerNervous
    ? `Nervous? ${kesslerNervous}\n`
    : "";
  const kesslerHopelessBody = !!kesslerHopeless
    ? `Hopeless? ${kesslerHopeless}\n`
    : "";
  const kesslerRestlessBody = !!kesslerRestless
    ? `Restless or fidgety? ${kesslerRestless}\n`
    : "";
  const kesslerDepressedBody = !!kesslerDepressed
    ? `So depressed that nothing could cheer you up? ${kesslerDepressed}\n`
    : "";
  const kesslerEffortBody = !!kesslerEffort
    ? `Everything needed effort to accomplish? ${kesslerEffort}\n`
    : "";
  const kesslerWorthlessBody = !!kesslerWorthless
    ? `Worthless? ${kesslerWorthless}\n`
    : "";
  const screeningBody =
    `\n- Screening -\n` +
    kesslerNervousBody +
    kesslerHopelessBody +
    kesslerRestlessBody +
    kesslerDepressedBody +
    kesslerEffortBody +
    kesslerWorthlessBody;

  // Assessment
  const hasWishedToBeDeadBody = !!hasWishedToBeDead
    ? `Have you wished you were dead or wished you could go to sleep and not wake up? ${hasWishedToBeDead}\n`
    : "";
  const hadSuicidalThoughtsBody = !!hadSuicidalThoughts
    ? `Have you actually had any thoughts about ending your life? ${hadSuicidalThoughts}\n`
    : "";
  const hasThoughtSuicidalMethodBody = !!hasThoughtSuicidalMethod
    ? `Have you thought about how you might do this? ${hasThoughtSuicidalMethod}\n`
    : "";
  const hadSuicidalIntentBody = !!hadSuicidalIntent
    ? `Have you had any intention to act on these thoughts? ${hadSuicidalIntent}\n`
    : "";
  const hadSuicidalIntentWithPlanBody = !!hadSuicidalIntentWithPlan
    ? `Have you started to work out the details or prepared the means to carry out this plan? ${hadSuicidalIntentWithPlan}\n`
    : "";
  const hadActedOnSuicidalThoughtsBody = !!hadActedOnSuicidalThoughts
    ? `Have you ever done anything or started to do anything to end your life? ${hadActedOnSuicidalThoughts}\n`
    : "";
  const suicideAttemptRecencyBody = !!suicideAttemptRecency
    ? `Was this within the past two (2) months? ${suicideAttemptRecency}\n`
    : "";
  const nonSuicidalSelfInjuryBody = !!nonSuicidalSelfInjury
    ? `Have you intentionally hurt or injured yourself for reasons other than wanting to end your life? ${nonSuicidalSelfInjury}\n`
    : "";
  const hasThoughtsOfHarmingOthersBody = !!hasThoughtsOfHarmingOthers
    ? `Do you have any thoughts of harming others? ${hasThoughtsOfHarmingOthers}\n`
    : "";
  const assessmentBody =
    `\n-Assessment-\n` +
    hasWishedToBeDeadBody +
    hadSuicidalThoughtsBody +
    hasThoughtSuicidalMethodBody +
    hadSuicidalIntentBody +
    hadSuicidalIntentWithPlanBody +
    hadActedOnSuicidalThoughtsBody +
    suicideAttemptRecencyBody +
    nonSuicidalSelfInjuryBody +
    hasThoughtsOfHarmingOthersBody;

  // Schedule Preference
  const firstChoiceScheduleBody = !!firstChoiceSchedule
    ? `Choice 1: ${firstChoiceSchedule?.day?.value}, ${firstChoiceSchedule?.time?.value}\n`
    : "";
  const secondChoiceScheduleBody = !!secondChoiceSchedule
    ? `Choice 2: ${secondChoiceSchedule?.day?.value}, ${secondChoiceSchedule?.time?.value}\n`
    : "";
  const thirdChoiceScheduleBody = !!thirdChoiceSchedule
    ? `Choice 3: ${thirdChoiceSchedule?.day?.value}, ${thirdChoiceSchedule?.time?.value}\n`
    : "";
  const ifScheduleUnavailableBody = !!ifScheduleUnavailable
    ? `We strive to match you with the best mental health professional, and maximize your ability to attend a consult. How long would you be willing to wait for a confirmed schedule? ${ifScheduleUnavailable}\n`
    : "";
  const bodySpecificIfScheduleUnavailableBody =
    ifScheduleUnavailable === "Others"
      ? !!specificIfScheduleUnavailable
        ? `Please tell us more about your preferred schedule: ${specificIfScheduleUnavailable}\n`
        : ""
      : "";
  const therapistPreferenceBody = !!therapistPreference
    ? `Therapist preference? ${therapistPreference}\n`
    : "";
  const otherNotesBody = !!otherNotes
    ? `Thank you for answering this intake form. Is there anything else you would like us to know? ${otherNotes}\n`
    : "";
  const preferenceBody =
    `\n- Preference -\n` +
    firstChoiceScheduleBody +
    secondChoiceScheduleBody +
    thirdChoiceScheduleBody +
    ifScheduleUnavailableBody +
    bodySpecificIfScheduleUnavailableBody +
    therapistPreferenceBody +
    otherNotesBody;

  return (
    personalInformationBody +
    medicalHistoryBody +
    mentalHealthInformationBody +
    screeningBody +
    assessmentBody +
    preferenceBody
  );
};
